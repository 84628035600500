<template>
    <div class="sup_content settings-couchDb">
        <div class="sup_page_heading">
            <h2>CouchDB Management</h2>
        </div>
        <div class="sup_inner_content">
            <DbActionArea v-if="couchData.environment === 'dev'" :name="'Staging Database'" :dbData="couchData.dbData1" :document="couchData.documentID" />
            <DbActionArea v-if="couchData.environment === 'prod'" :name="'Hulk Database:'" :dbData="couchData.dbData1" :document="couchData.documentID" />
            <DbActionArea v-if="couchData.environment === 'prod'" :name="'Thor Database:'" :dbData="couchData.dbData2" :document="couchData.documentID" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import { adminCouchDB } from "../../../../config/appUrls";
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { onMounted, reactive } from 'vue';
import DbActionArea from '../../views/dbActionArea'

export default {
    name: "CounchDB",
    components: {
        DbActionArea
    },
    setup() {
        let store = useStore();

        const couchData = reactive({
            dbData1: [
                {name: "All"}
            ],
            dbData2: [
                {name: "All"}
            ],
            documentID: [
                {name: "All"}
            ],
            environment: null
        });

        onMounted(()=>{
            fetchCouchData();
        })

        const fetchCouchData = async() => {
            try{
                const response = await axios.get(adminCouchDB, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                for(let i=0; i<response.data.data[0].couchDb.length; i++){
                    couchData.dbData1.push({
                        name: response.data.data[0].couchDb[i]
                    });
                }
                for(let i=0; i<response.data.data[0].couchDb1.length; i++){
                    couchData.dbData2.push({
                        name: response.data.data[0].couchDb1[i]
                    });
                }
                for(let i=0; i<response.data.data[0].document.length; i++){
                    couchData.documentID.push({
                        name: response.data.data[0].document[i]
                    });
                }
                couchData.environment = response.data.data[0].couchDb.length > 0 && response.data.data[0].couchDb1.length > 0 ? "prod" : "dev";
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        return{
           couchData
        }
    }
}
</script>

<style lang="scss">
.sup_db_actions{
    .p-button{
        width: auto;
        min-width: 100px;
    }
}
</style>